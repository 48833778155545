/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}




@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./fonts/SF-Pro-Rounded-Heavy.otf') format('opentype');
  /* You can add additional font properties here if needed */
}

body {
  font-family: 'SF Pro Rounded', sans-serif;
}











.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


ul {
  list-style-type: none;
}
li {
  padding: 5px;
}

/* Center the login container */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
}


/* Add border radius to textarea and link input box */
textarea, input[type="text"] {
  margin-bottom: 10px;
  font-size: 16px;
  /* width: calc(100% - 22px);  */
  /* Adjust width to account for padding */

  width: 100%; /* Set width to 100% to fill container */
  box-sizing: border-box; /* Ensure padding is included in width */


  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  resize: vertical; /* Allow vertical resizing only */
}




/* ___________logo box______________ */


/* Logo box ----------------------------- */
.logobox {
  display: flex;
  align-items: center;  
  flex-direction: row; 
}
.logobox img {
  margin-right: 10px; /* Adjust the spacing between the image and text as needed */
  z-index:9999;
  height: 35vh;
}

@media (prefers-reduced-motion: no-preference) {
  .Animate-logo {
      animation: App-logo-spin infinite 50s linear;
  }
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}


/*---School-------------------------*/
@keyframes rotateShadow {
  0% {
    text-shadow: 50px 20px 90px #40D3F3, -30px 0px 80px #CA52B7;
  }
  25% {
    text-shadow: 30px 50px 90px #40D3F3, 0px -30px 80px #CA52B7;
  }
  50% {
    text-shadow: -50px 20px 90px #40D3F3, 30px 0px 80px #CA52B7;
  }
  75% {
    text-shadow: -30px -50px 90px #40D3F3, 0px 30px 80px #CA52B7;
  }
  100% {
    text-shadow: 50px 20px 90px #40D3F3, -30px 0px 80px #CA52B7;
  }
}

.school {
  position: relative;
  font-family: 'SF Pro Rounded', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 130px;
  line-height: 20vh;
  color: #FFFFFF;
}

.school::before {
  content: "Easy SMM";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  text-shadow: 50px 20px 90px #40D3F3, -30px 0px 80px #CA52B7;
  animation: rotateShadow 50s infinite linear;
}

























/* App Container */
.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

/* Login Container */
.login-container {
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-container h1 {
  font-size: 24px;
  /* margin-bottom: 20px; */
}

.login-container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1877f2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #166fe5;
}

/* Main Container */
.main-container {
  width: 80%;
  max-width: 800px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.main-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Post Form */
.post-form {
  margin-bottom: 20px;
}

.post-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pages-list {
  list-style-type: none;
  padding: 0;
}

.pages-list li {
  margin-bottom: 10px;
}

.pages-list button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #1877f2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pages-list button:hover {
  background-color: #166fe5;
}

/* Posted Links */
.posted-links {
  margin-top: 20px;
}

.posted-links h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.posted-links ul {
  padding: 0;
  list-style-type: none;
}

.posted-links li {
  margin-bottom: 5px;
}

.posted-links a {
  color: #1877f2;
  text-decoration: none;
}

.posted-links a:hover {
  text-decoration: underline;
}






/* FB post Button */

.fb-post-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #1877f2; /* Facebook blue */
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fb-post-button:hover {
  background-color: #165dc1; /* Slightly darker blue for hover effect */
}

.fb-post-button:active {
  background-color: #144aa6; /* Even darker blue for active effect */
}
